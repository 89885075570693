<script>
import { Menu, Submenu, MenuItem } from "element-ui";

import { createNamespacedHelpers } from "vuex";

const { mapGetters: menuStoreGetters } =
  createNamespacedHelpers("menuStore");

export default {
  name: "left-menu",
  computed: {
    ...menuStoreGetters(["menus"]),
  },
  render() {
    const collapse = false;

    const defaultActive = this.$route.path;

    const iconRender = (item) => {
      if (item.attr.icon) {
        return <i class={item.attr.icon}></i>;
      } else {
        return <span></span>;
      }
    };

    const subMenuRender = (parentPath, menus) => {
      if (!menus) {
        return <span></span>;
      }
      return menus.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <el-sub-menu index={item.attr.page}>
              <template slot="title">
                {iconRender(item)}
                <span>{item.name}</span>
              </template>
              {subMenuRender(parentPath + item.attr.page, item.children)}
            </el-sub-menu>
          );
        } else {
          return (
            <el-menu-item index={parentPath + item.attr.page}>
              {iconRender(item)}
              <span slot="title">{item.name}</span>
            </el-menu-item>
          );
        }
      });
    };

    return (
      <el-menu
        collapse={collapse}
        unique-opened={true}
        router={true}
        default-active={defaultActive}
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        {subMenuRender("", this.menus)}
      </el-menu>
    );
  },
  components: {
    "el-menu": Menu,
    "el-sub-menu": Submenu,
    "el-menu-item": MenuItem,
  },
};
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 190px;
  min-height: 400px;
}
.el-menu {
  border-right: 0px;
  text-align: left;
}
</style>